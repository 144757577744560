import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import Home from './components/Home'
import UxDesign from './components/UxDesign'
import './App.css';

function App() {
  return (
    <BrowserRouter>
    <Route exact path='/' component={Home} />
    <Route exact path='/Ux' component={UxDesign} />
  </BrowserRouter>
  );
}

export default App;
