import React from 'react';
import centiment from '../images/Ux/centiment.jpeg';
import gilt from '../images/Ux/gilt.jpeg';
import justLashes from '../images/Ux/justLashes.png';
import rei from '../images/Ux/rei.jpeg';
import wwf from '../images/Ux/wwf.jpeg';
import './styles/Ux.css'

const UxDesign = () => {
    return (
        <div>
            <div className='itemBox'>
                <h2>Just Lashes Startup</h2>
                <img src={justLashes} className='uxImg' alt="just lashes website on phones and computers" />
                <p>As the only UX designer for the Just Lashes startup, I completely designed, researched and wrote copy for the site. </p>
                <p>As part of my research, I analyzed and compared our competitors. From them, I gathered integral information as well as the specific language we used for our site. I also performed usability testing on the competitor sites.</p>
                <p>I then designed a responsive website, wrote the site copy, and performed usability testing. Once the design was complete, I worked with my photographer—Nickolus Gragg—to photograph the site product. I then edited the photos and incorporated them into the design (with the exception of the model photographs, which the client chose to re-edit herself).</p>
            </div>

            <div className='itemBox'>
                <h2>Centiment.io</h2>
                <img src={centiment} className='uxImg' alt="centiment website on phones and computers" />

                <p>I was hired after my team’s contract ended as the Lead (and only) UX Designer for the Centiment startup. In addition to my UX responsibilities, I was responsible for all company presentations, company graphic design, and creative collaboration with our investors. </p>
                <hr id='uxHr'/>
                <p>Part of a 4 person team working with our client Centiment.io. Together we were responsible for collaborative research, including user interviews, usability testing, affinity mapping and the creation of personas.</p>
                <p>We then designed our concepts together using design studio, hand sketches, white boarding, wire framing and prototyping. In addition to redesigning the site, we also designed and produced the new feature "Audience." Additionally, I was in charge of communicating with our client and stakeholders.</p>
            </div>

            <div className='itemBox'>
                <h2>Women & Women First</h2>
                <img src={wwf} className='uxImg' alt="women and women first website on phones" />

                <p>This is my passion project. I was part of a 3 person team including Amy Spengler and Julia Park. Together we collaboratively researched women in STEM careers, specifically Million Women Mentors in order to create a sister app that connects women searching for and wanting to be mentors. While researching, we performed user interviews and collected research surveys and affinity mapped for "I" statements.</p>
                <p>We then used design studio to formulate features, that we subsequently turned into hand sketches, then wireframes.</p>
                <p>This project has been featured on <span><a href="https://blog.prototypr.io/women-and-women-first-a97a53876b22" target="_blank">prototypr.io.</a></span></p>
            </div>

            <div className='itemBox'>
                <h2>REI</h2>
                <img src={rei} className='uxImg' alt="REI website on phones and computers" />

                <p>Research Team: Myself, Andrew Steiner and Frannie Laks.</p> 
                <p>Design Team: Myself</p>
                <p>This was a two week project, the first week was specifically for research. My small three person team and I collaboratively conducted field research in an REI store, usability tests on REI.com, and card sorting for navigation names. We also created a site-map and did a heuristic evaluation.</p>
                <p>During the design portion, I worked on my own to restructure the header navigation based on the results from the card sorting. As per our users' needs, I also added an experts feature which allows users to interact with professionals in their area. Additionally, I created an option that allowed users to schedule appointments for backpack fittings in store.</p>
            </div>

            <div className='itemBox'>
                <h2>GILT Style Club</h2>
                <img src={gilt} className='uxImg' alt="Gilt Style Club website on phones" />

                <p>I was part of a 3 person group including Edward Enrique and Annie Meng, we created the feature Style Club for the GILT mobile app. I lead the design portion by keeping the master Sketch file and making sure everyone's screens were cohesive, the colors and fonts were correct and made sure everyone was using the proper spacing.  We collaboratively performed user interviews and usability tests on GILT's current app. We then performed design studio together, created hand sketches and then wireframes. We performed two rounds of usability testing on our prototypes before creating our final mockups.</p>
            </div>
        </div>
    )
}

export default UxDesign