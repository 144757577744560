import React from 'react';
import { Link } from 'react-router-dom';
import Header from './Header';
import './styles/Home.css';
import frenchysDesk from '../images/frenchysDesk.png';
import frenchyContact from '../images/frenchyContact.png';
import frenchy from '../images/frenchy.JPG';
import theGoodWord from '../images/theGoodWord.gif';
import fantasyFutball from '../images/FantasyFutball.gif';
import ScareBnBFinal from '../images/ScareBnBFinal.gif';
import PersonalSpeakeasy from '../images/PersonalSpeakeasy.gif';
import Eatzilla from '../images/EatZilla.gif';
import mailImg from '../images/mailTo.png';
import Resume from '../images/FrenchysWebResume.png';
import frenchysUx from '../images/Ux/frenchysUx.png'

import uxDesign from './UxDesign'
import UxDesign from './UxDesign';

const Home = () => {
    return (
        <div>
            <main>
                <Header />
                <div className='heroBox'>
                    <div className='heroTransparent'>

                        <img src={frenchy} className='frenchysFace' alt="headshot of frenchy looking to the right" />

                        <h1 className='frenchyTitle'>Frenchy French</h1>
                        <h2 className='frenchySubTitle'>Software Engineer</h2>

                <form action="https://github.com/frinci">
                    <input type="submit" formtarget="_blank" className='readMoreButton' id='heroButtons' value="Github" />
                </form>
                <form action="#Contact">
                    <input type="submit" className='readMoreButton' value="Contact" />
                </form>
                        <p className='brandStatement'>I am a driven and dedicated fullstack software engineer with a passion for diversity and learning. With a background in UX design and IT, I tend to think both analytically and creatively. Because of this, I like to pair my strong problem solving skills with aesthetically pleasing design, to create solid, well rounded applications. As a forever learner, I’m always finding ways to increase my knowledge and add more tools to my wheelhouse. Additionally, my educational background in psychology makes me a skillful communicator, allowing me to translate complex code into easy to understand concepts. At my core I believe that knowledge, a strong skillset, and community mindedness make the best user-focused programming. </p>
                    </div>

                    <img src={frenchysDesk} className='hero' alt="a computer connected to a monitor with code on the screen on a desk, in front of window" />
                </div>

                <a id="Work"></a>
                <h1 className='workTitle'>Work</h1>


                <div className='workBox'>
                <h2 className='title2'>The Good Word</h2>
                    <div className='workItem'>
                        <div className='gridItem'>
                            <img src={theGoodWord} className='homeGif' alt="gif of the good word site" />

                            <div className='buttonBox'>
                                <form action="https://github.com/frinci/theGoodWord">
                                    <input type="submit" formtarget="_blank" value="Github" className="readMoreButton" />
                                </form>
                            </div>
                        </div>

                        <div className='gridItem'>
                            <h2 className='title1'>The Good Word</h2>

                            <p>The Good Word is a satirical news news site with a little something to tickle anyone's fancy.</p>
                            <p>This site was created using React.js, along with CSS and HTML for the front end and Ruby on Rails for the backend. Its articles were written by Frenchy's colleagues and are stored within a database in the backend. It has full CRUD capabilities.</p>
                            <p>The Good Word main page displays all articles. Each article is filtered by category and accessible via the navigation. The user can also create their own articles. When clicked, the articles are rendered in an Article component, that is dependent on the its props. There, the articles can be viewed, updated, or deleted.</p>
                        </div>
                    </div>

                    <hr />

                    <div className='workItem'>
                        <div className='gridItem'>
                            <h2>EatZilla</h2>

                            <img src={Eatzilla} id='img2' className='homeGif' alt="gif of EatZilla website" />

                            <p>Welcome to EatZilla!</p>
                            <p>This site was created using React and React Bootstrap.</p>
                            <p>EatZilla is a site that allows users to find quick recipes, based on the category of their preference and have the ingredients delivered to them by 6pm. The fictional site would work in collaboration with local grocery stores--based on the user's location--who would deliver the ingredients.</p>
                            <p>EatZilla is a one page responsive site, created in collaboration with a team of UX designers. The software engineers and UX designers performed feature prioritization and created a Moscow map together. The UX team then created low fidelity mockups and together we performed usability testing. Based on that testing, the UX team created final mockups, which we--the software engineering team--created using React. The header and carousel are comprised of two components each, which are alternately hidden at specific breakpoints.</p>
                        </div>

                        <div className='gridItem'>
                            <img src={Eatzilla} id='img1' className='homeGif' alt="gif of EatZilla website" />

                            <div className='buttonBox'>
                                <form action="https://github.com/frinci/Eatzilla">
                                    <input type="submit" formtarget="_blank" value="Github" className="readMoreButton" />
                                </form>

                                <form action="http://eatzilla.surge.sh/">
                                    <input type="submit" formtarget="_blank" value="Deployed Site" className="readMoreButton" />
                                </form>
                            </div>
                        </div>

                    </div>

                    <hr />

                    <div className='workItem'>
                    <h2 className='title2'>Fantasy Futball</h2>
                        <div className='gridItem'>
                            <img src={fantasyFutball} className='homeGif' alt="gif of the fantasy futball website" />

                            <div className='buttonBox'>
                                <form action="https://github.com/gdelv/p3-fantasy-futbal/blob/master/README.md">
                                    <input type="submit" formtarget="_blank" value="Github" className="readMoreButton" />
                                </form>
                            </div>
                        </div>

                        <div className='gridItem'>
                            <h2 className='title1'>Fantasy Futball</h2>
                            <p>Fantasy Futball is a mobile website for Futball fans to sign in and create their rosters for their own fantasy sports games!</p>
                            <p>This site was created using React.js along with HTML, and CSS on the front end and Express on the back end. It has authentication set up so that user's can create an account, log in, and then create, edit, and delete their rosters.</p>
                        </div>
                    </div>

                    <hr />

                    <div className='workItem'>
                        <div className='gridItem'>
                            <h2>Personal Speakeasy</h2>

                            <img src={PersonalSpeakeasy} id='img2' className='homeGif' alt="gif of Personal Speakeasy Website" />

                            <p>Welcome to your own personal speakeasy!</p>
                            <p>This site was created with HTML, JavaScript, CSS, and React.</p>
                            <p>This basic web app allows you to search through cocktails for inspiration and create your own. It's perfect for creating shareable cocktail menus for parties or training material for restaurants.</p>
                        </div>

                        <div>
                            <img src={PersonalSpeakeasy} id='img1' className='homeGif' alt="gif of Personal Speakeasy Website" />

                            <div className='buttonBox'>
                                <form action="https://github.com/frinci/personalSpeakeasy">
                                    <input type="submit" formtarget="_blank" value="Github" className="readMoreButton" />
                                </form>

                                <form action="http://personalspeakeasy.surge.sh/?">
                                    <input type="submit" formtarget="_blank" value="Deployed Site" className="readMoreButton" />
                                </form>
                            </div>
                        </div>
                    </div>

                    <hr />

                    <div className='workItem'>
                    <h2 className='title2'>Scare BnB</h2>
                        <div className='gridItem'>
                            <img src={ScareBnBFinal} className='homeGif' alt="gif of Scare Bnb website" />

                            <div className='buttonBox'>
                                <form action="https://github.com/frinci/ScareBnB">
                                    <input type="submit" formtarget="_blank" value="Github" className="readMoreButton" />
                                </form>

                                <form action="https://frinci.github.io/ScareBnB/">
                                    <input type="submit" formtarget="_blank" value="Deployed Site" className="readMoreButton" />
                                </form>
                            </div>
                        </div>

                        <div className='gridItem'>
                        <h2 className='title1'>Scare BnB</h2>
                        <p>Welcome to the spookiest of bed and breakfasts!</p>
                        <p>This site was created without a backend using HTML, CSS, and JavaScript.</p>
                        <p>ScareBnB is a place for ghouls to rent the perfect haunted house for thier vacation. They're given the choice between two victorian manors; one an imposing mansion on the edge of a graveyard and the other a perfectly dilapidated witches lare in an enchanted forest. While visiting, ghouls can roam the beautiful graveyards, or commune with the local ghosts. These haunted manors also come fulling stocked with everything needed to fulfill your every spooky need! Happy seance, and enter if you dare 🎃☠️🎃</p>
                        </div>
                    </div>
                </div>


                <a id="Contact"></a>
                <div className='heroBox2'>
                    <img src={frenchyContact} className='hero' alt="Frenchy typing at a computer next to a window" />

                    <h1 className='frenchyTitle2'>Contact Frenchy</h1>
                </div>

                <div className='contactBox'>
                    <div className='emailBox'>
                        <a href="mailto:frincicodes@frinci.com" target='_blank'>
                            <img src={mailImg} className='mailTo' alt="raven logo with envelope" />
                            <h2 className='emailMe'>Email Frenchy</h2>
                        </a>
                    </div>

                    <div className='resumeBox'>
                        <a className='lightbox' href="#resume">
                            <img src={Resume} alt="Frenchy's resume" />
                        </a>
                    </div>

                    <div className='lightbox-target' id='resume'>
                        <img src={Resume} alt="Frenchy's resume" />
                        <a className='lightbox-close' href="#"></a>
                    </div>


                    <div className='uxDesign'>
                        <a id="Ux"></a>
                        <div className='heroBox2'>
                            <img src={frenchysUx} className='hero' alt="ux wireframes on computers and phones" />
                            <h1 className='frenchyTitle2'>Ux Design</h1>
                            <p className='uxIntro'>In addition to being a fullstack software engineer, I also worked, previously, as a UX designer and researcher. As a result, design and aesthetics are an important part of my builds.</p>

                            <div className='buttonBox2'>
                                <form action="https://frincidesigns.com">
                                    <input type="submit" formtarget="_blank" value="UX Design Portfolio" className="readMoreButton" id='uxButton' />
                                </form>
                            </div>
                        </div>
                    </div>
                    <UxDesign />
                </div>

            </main>
        </div>
    )
}

export default Home