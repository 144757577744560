import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../images/frinciCodes.png'
import './styles/Header.css'

const Header = () => {
    return (
        <div className='navContainer'>
            <div className='listBox'>
                <nav>
                    <a href="/">
                        <img src={Logo} className='logo' alt="illustrated raven skull" />
                    </a>

                    <ul>
                        <li><a href="/" >Home</a></li>
                        <li><a href="#Contact">Contact</a></li>
                        <li><a href="#Work">Work</a></li>
                        <li><a href="#Ux">Ux Design</a></li>
                    </ul>
                </nav>
            </div>
        </div>
    )
}

export default Header